import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        name: "Default Homepage",
        component: () => import("../views/LoginPage.vue"),
    },
    {
    path: "/login/:quiz",
    name: "Homepage",
    component: () => import("../views/LoginPage.vue"),
  },
  {
    path: "/welcome",
    name: "welcome",
    component: () => import("../views/WelcomePage.vue"),
  },
  {
    path: "/quiz",
    name: "quiz",
    component: () => import("../views/QuizPage.vue"),
  },
  {
    path: "/result",
    name: "result",
    component: () => import("../views/ResultPage.vue"),
  },
  {
    path: "/result/mental-health",
    name: "result",
    component: () => import("../views/MentalHealthResultPage.vue"),
  },
  {
        path: "/result/hiv-quiz",
        name: "result",
        component: () => import("../views/HIVQuizResultPage.vue"),
  },
  {
    path: "/success",
    name: "success",
    component: () => import("../views/SuccessPage.vue"),
  },
];

export default new VueRouter({
  routes,
});
